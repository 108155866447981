import { SET_USER_DETAIL, LOG_OUT } from "./../actions/actionType";

const initialState = {
  userid: 0,
  email: "",
  password: "",
  name: "",
};

const users_reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DETAIL:
      return {
        ...state,
        userid: action.payload.id,
        email: action.payload.email,
        name: action.payload.name,
      };
    case LOG_OUT:
      return {
        ...state,
        userid: 0,
        email: "",
        password: "",
        name: "",
      };

    default:
      return state;
  }
};

export default users_reducer;
