import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import Icon, { Icons } from './Icons';
import Colors from './../constants/Colors';
import * as Animatable from 'react-native-animatable';

import Home from './../screens/Home';
import { Button } from 'react-native-paper';
import AwesomeAlert from 'react-native-awesome-alerts';
import { useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { setLogout } from '../store/actions/userAction';
import Logo from './Logo';
import BackButton from './BackButton';
import CategoryDetail from '../screens/CategoryDetail';
import MenuDetail from '../screens/MenuDetail';
import MyApp from '../screens/MyApp';
import OutletDetail from '../screens/OutletDetail';
import OutletMenuDesc from '../screens/OutletMenuDesc';
import Profile from '../screens/Profile';
const TabArr = [
  {
    route: 'Home',
    label: 'Home',
    type: Icons.Ionicons,
    activeIcon: 'grid',
    inActiveIcon: 'grid-outline',
    component: Home,
  },

  {
    route: 'OutletDetail',
    label: 'Outlet',
    type: Icons.MaterialCommunityIcons,
    activeIcon: 'dice-5',
    inActiveIcon: 'dice-5-outline',
    component: OutletDetail,
  },
  {
    route: 'CategoryDetail',
    label: 'Category',
    type: Icons.MaterialCommunityIcons,
    activeIcon: 'credit-card-scan',
    inActiveIcon: 'credit-card-scan-outline',
    component: CategoryDetail,
  },
  {
    route: 'MenuDetail',
    label: 'Menu',
    type: Icons.MaterialCommunityIcons,
    activeIcon: 'clipboard-check',
    inActiveIcon: 'clipboard-check-outline',
    component: MenuDetail,
  },
  {
    route: 'OutletMenuDesc',
    label: 'Outlet Description',
    type: Icons.MaterialCommunityIcons,
    activeIcon: 'calendar-text',
    inActiveIcon: 'calendar-text-outline',
    component: OutletMenuDesc,
  },
  {
    route: 'Profile',
    label: 'Profile',
    type: Icons.MaterialCommunityIcons,
    activeIcon: 'account-settings',
    inActiveIcon: 'account-settings-outline',
    component: Profile,
  },
];

const Tab = createBottomTabNavigator();

const TabButton = (props) => {
  const { item, onPress, accessibilityState } = props;
  const focused = accessibilityState.selected;
  const viewRef = useRef(null);
  useEffect(() => {
    if (focused) {
      viewRef.current.animate({
        0: { scale: 0.5, rotate: '0deg' },
        1: { scale: 1.5, rotate: '360deg' },
      });
    } else {
      viewRef.current.animate({
        0: { scale: 1.5, rotate: '360deg' },
        1: { scale: 1, rotate: '0deg' },
      });
    }
  }, [focused]);

  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={onPress} activeOpacity={1}>
        <Animatable.View ref={viewRef} duration={1000} style={styles.container}>
          <Icon
            type={item.type}
            name={focused ? item.activeIcon : item.inActiveIcon}
            color={focused ? Colors.primary : Colors.primaryLite}
          />
        </Animatable.View>
      </TouchableOpacity>
    </View>
  );
};

export default function BottomNav() {
  const [showAlert, setshowAlert] = useState(false);
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const showAlertDialog = () => {
    setshowAlert(true);
  };
  const hideAlert = () => {
    setshowAlert(false);
  };
  const Logout_fun = () => {
    dispatch(setLogout(true));
    AsyncStorage.clear();
    // props.navigation.replace("Login");
    setshowAlert(false);
    navigation.reset({
      index: 0,
      routes: [{ name: 'Login' }],
    });
  };
  return (
    <View style={{ flex: 1 }}>
      <Tab.Navigator
        screenOptions={{
          headerShown: true,
          headerStyle: {
            backgroundColor: '#4f1601',
          },
          headerTitle: (props) => <Logo {...props} />,
          tabBarStyle: {
            height: 60,
            position: 'absolute',
            bottom: 16,
            right: 16,
            left: 16,
            borderRadius: 16,
          },
        }}
      >
        {TabArr.map((item, index) => {
          return (
            <Tab.Screen
              key={index}
              name={item.route}
              component={item.component}
              options={{
                tabBarShowLabel: false,
                tabBarButton: (props) => <TabButton {...props} item={item} />,
                headerRight: () => (
                  <Button onPress={showAlertDialog} title='Info'>
                    Log Out
                  </Button>
                ),
              }}
            />
          );
        })}
      </Tab.Navigator>
      <AwesomeAlert
        show={showAlert}
        showProgress={false}
        title='Logout'
        message='Really want to logout'
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showCancelButton={true}
        showConfirmButton={true}
        cancelText='No, cancel'
        confirmText='Yes, Logout me'
        confirmButtonColor='#DD6B55'
        onCancelPressed={hideAlert}
        onConfirmPressed={Logout_fun}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
