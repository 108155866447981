import { View, Text, StyleSheet } from 'react-native';
import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import {
  StartScreen,
  LoginScreen,
  RegisterScreen,
  ResetPasswordScreen,
  Dashboard,
  PlanDetail,
  CreateEWallet,
  PlanInfo,
} from './../screens';
import BottomNav from '../components/BottomNav';
import Home from '../screens/Home';
import Logo from '../components/Logo';
import { Button } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import MenuListOnOutlet from '../screens/MenuListOnOutlet';
// import MainDrawerNavigation from "./MainDrawerNavigation";

const Stack = createStackNavigator();

function UnauthNavigation() {
  const navigation = useNavigation();
  return (
    <Stack.Navigator
      options={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name='Home'
        component={Home}
        options={{
          headerShown: true,
          headerRight: () => (
            <Button
              style={style.ButtonStyle}
              icon='login-variant'
              labelStyle={{ fontSize: 12 }}
              onPress={() =>
                navigation.reset({
                  index: 0,
                  routes: [{ name: 'Login' }],
                })
              }
            >
              <Text>Login</Text>
            </Button>
          ),
        }}
      />
      <Stack.Screen
        name='Login'
        component={LoginScreen}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name='MenuListOnOutlet'
        component={MenuListOnOutlet}
        options={{
          headerShown: true,
          title: 'Menu List',
        }}
      />
      <Stack.Screen
        name='BottomNav'
        component={BottomNav}
        options={{
          headerShown: false,
        }}
      />
    </Stack.Navigator>
  );
}

export default UnauthNavigation;

const style = StyleSheet.create({
  ButtonStyle: {
    margin: 7,
    width: '50%',
  },
});
