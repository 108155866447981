import React, { useState } from "react";
import { View, StyleSheet, TouchableOpacity, Alert } from "react-native";
import { Text } from "react-native-paper";
import Background from "../components/Background";
import Logo from "../components/Logo";
import Header from "../components/Header";
import Button from "../components/Button";
import TextInput from "../components/TextInput";
import BackButton from "../components/BackButton";
import { theme } from "../core/theme";
import { emailValidator } from "../helpers/emailValidator";
import { passwordValidator } from "../helpers/passwordValidator";
import { nameValidator } from "../helpers/nameValidator";
import { referralValidator } from "../helpers/referralValidator";
import Axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import CryptoJS from "react-native-crypto-js";
import { useDispatch } from "react-redux";
import { SetuserDetail } from "../store/actions/userAction";
import AwesomeAlert from "react-native-awesome-alerts";

export default function RegisterScreen({ navigation }) {
  const [name, setName] = useState({ value: "", error: "" });
  const [email, setEmail] = useState({ value: "", error: "" });
  const [password, setPassword] = useState({ value: "", error: "" });
  const [referral, setreferral] = useState({
    value: "0x6f296f1eB66e343F7Eab07f9d72Bdc58CA2c1e4c",
    error: "",
  });
  const [showAlert, setshowAlert] = useState(false);
  const dispatch = useDispatch();

  const onSignUpPressed = () => {
    const nameError = nameValidator(name.value);
    const emailError = emailValidator(email.value);
    const passwordError = passwordValidator(password.value);
    const referralError = referralValidator(referral.value);
    if (emailError || passwordError || nameError || referralError) {
      setName({ ...name, error: nameError });
      setEmail({ ...email, error: emailError });
      setPassword({ ...password, error: passwordError });
      setreferral({ ...referral, error: referralError });
      return;
    }
    // navigation.reset({
    //   index: 0,
    //   routes: [{ name: "Dashboard" }],
    // });
    Axios.post("https://cryptosharkglobal.com:3005/api/signup", {
      name: name.value,
      email: email.value,
      password: password.value,
      referral: referral.value,
    })
      .then((responce) => {
        if (responce.data) {
          var localdata = {
            id: responce.data,
            email: email.value,
            name: name.value,
            referral: referral.value,
            planid: "",
          };

          var data = {};
          data = {
            id: responce.data.insertId,
            email: email.value,
            password: password.value,
            name: name.value,
            referral: referral.value,
            planid: "",
            tInfo: "",
            IsActive: 0,
            dailyLimit: 0,
            MembershipTill: 0,
            PlanTill: 0,
          };
          var ciphertext = CryptoJS.AES.encrypt(
            JSON.stringify(localdata),
            "EBaySocial@830"
          ).toString();
          storeData(ciphertext);
          dispatch(SetuserDetail(data));
          navigation.reset({
            index: 0,
            routes: [{ name: "CreateEWallet" }],
          });
        } else {
          setshowAlert(true);
        }
      })
      .catch((error) => {});
  };

  const storeData = async (value) => {
    try {
      await AsyncStorage.setItem("@SocialHandeler", value);
    } catch (e) {
      // saving error
    }
  };
  const hideAlert = () => {
    setshowAlert(false);
  };
  return (
    <Background>
      <Logo />
      <Header>Create Account</Header>
      <TextInput
        label="Name"
        returnKeyType="next"
        value={name.value}
        onChangeText={(text) => setName({ value: text, error: "" })}
        error={!!name.error}
        errorText={name.error}
      />
      <TextInput
        label="Email"
        returnKeyType="next"
        value={email.value}
        onChangeText={(text) => setEmail({ value: text, error: "" })}
        error={!!email.error}
        errorText={email.error}
        autoCapitalize="none"
        autoCompleteType="email"
        textContentType="emailAddress"
        keyboardType="email-address"
      />
      <TextInput
        label="Password"
        returnKeyType="next"
        value={password.value}
        onChangeText={(text) => setPassword({ value: text, error: "" })}
        error={!!password.error}
        errorText={password.error}
        secureTextEntry
      />
      <TextInput
        label="Referral"
        returnKeyType="done"
        value={referral.value}
        onChangeText={(text) => setreferral({ value: text, error: "" })}
        error={!!referral.error}
        errorText={referral.error}
      />
      <Button
        mode="contained"
        onPress={onSignUpPressed}
        style={{ marginTop: 24 }}
      >
        Sign Up
      </Button>
      <View style={styles.row}>
        <Text>Already have an account? </Text>
        <TouchableOpacity onPress={() => navigation.navigate("Login")}>
          <Text style={styles.link}>Login</Text>
        </TouchableOpacity>
      </View>
      <AwesomeAlert
        show={showAlert}
        showProgress={false}
        title="User Exist or Invalid Raferals"
        message="Email Already Exist or Raferals Not Exist..."
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showCancelButton={false}
        showConfirmButton={true}
        confirmText="Ok, Thanks"
        confirmButtonColor="#DD6B55"
        onConfirmPressed={hideAlert}
      />
    </Background>
  );
}

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    marginTop: 4,
  },
  link: {
    fontWeight: "bold",
    color: theme.colors.primary,
  },
});
