import { SET_USER_DETAIL, LOG_OUT } from "./actionType";

export const SetuserDetail = (user) => ({
  type: SET_USER_DETAIL,
  payload: user,
});
export const setLogout = (wallet) => ({
  type: LOG_OUT,
  payload: wallet,
});
