import { View, Text } from "react-native";
import React from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import {
  StartScreen,
  LoginScreen,
  RegisterScreen,
  ResetPasswordScreen,
  Dashboard,
  PlanDetail,
  CreateEWallet,
  PlanInfo,
} from "./../screens";
// import MainDrawerNavigation from "./MainDrawerNavigation";
// import Home from "../screens/Home";
import BottomNav from "../components/BottomNav";
import MenuListOnOutlet from "../screens/MenuListOnOutlet";
import Logo from "../components/Logo";

const Stack = createStackNavigator();

function AuthNavigation() {
  return (
    <Stack.Navigator
      options={{
        headerShown: false,
      }}
    >
      <Stack.Screen
        name="BottomNav"
        component={BottomNav}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="Login"
        component={LoginScreen}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="MenuListOnOutlet"
        component={MenuListOnOutlet}
        options={{
          headerShown: true,
          title: "Menu List",
        }}
      />
    </Stack.Navigator>
  );
}

export default AuthNavigation;
