import React, { Component, useState, useEffect } from "react";
import AwesomeAlert from "react-native-awesome-alerts";
import DropDownPicker from "react-native-dropdown-picker";
import { SearchBar } from "react-native-elements";
import {
  ScrollView,
  Switch,
  StyleSheet,
  Text,
  View,
  Image,
  Modal,
  FlatList,
  Picker,
} from "react-native";

import Axios from "axios";
import { Button, Headline } from "react-native-paper";
import { useNavigation } from "@react-navigation/native";
import TextInput from "../components/TextInput";
import {
  CategoryValidator,
  nameValidator,
  OutletValidator,
  priceValidator,
} from "../helpers/nameValidator";
const MenuDetail = () => {
  const [showAlert, setshowAlert] = useState({ id: 0, status: false });
  const [showMsg, setshowMsg] = useState({ title: "", msg: "", status: false });
  const [AddEditVisiable, setAddEditVisiable] = useState(false);
  const [diffVersionAlert, setdiffVersionAlert] = useState(false);
  const [OutletDetail, setOutletDetail] = useState([]);
  const [Outletvalue, setOutletvalue] = useState(0);
  const [Categoryvalue, setCategoryvalue] = useState(0);
  const [showOutletDropDown, setshowOutletDropDown] = useState(false);
  const [showCategoryDropDown, setshowCategoryDropDown] = useState(false);
  const [CategoryDetail, setCategoryDetail] = useState([]);
  const [MenuDataDetail, setMenuDataDetail] = useState([]);
  const [MenuDataFilter, setMenuDataFilter] = useState([]);
  const [SearchMenu, setSearchMenu] = useState("");
  const [MenuMasterDetail, setMenuMasterDetail] = useState({
    value: "",
    error: "",
    id: 0,
    price: 0,
    outletId: 0,
    catId: 0,
  });
  const navigation = useNavigation();
  useEffect(() => {
    Axios.get("https://cryptosharkglobal.com:3010/api/getMenuDetail").then(
      (responce) => {
        setMenuDataDetail(responce.data);
        setMenuDataFilter(responce.data);
      }
    );
    Axios.get("https://cryptosharkglobal.com:3010/api/getOutletDetail").then(
      (responce) => {
        const data = responce.data;
        const tList = data.map((d) => ({
          label: d.Outlet_name,
          value: d.id,
        }));
        setOutletDetail(tList);
      }
    );
    Axios.get("https://cryptosharkglobal.com:3010/api/getCategoryDetail").then(
      (responce) => {
        const data = responce.data;
        const tList = data.map((d) => ({
          label: d.cat_name,
          value: d.id,
        }));
        setCategoryDetail(tList);
      }
    );
  }, []);

  const setOutletData = (val) => {
    if (val !== 0) {
      // setOutletvalue(val);
      setMenuMasterDetail({
        ...MenuMasterDetail,
        outletId: val,
      });
    }
  };
  const setCategoryData = (val) => {
    if (val !== 0) {
      // setCategoryvalue(val);
      setMenuMasterDetail({
        ...MenuMasterDetail,
        catId: val,
      });
    }
  };
  const showMenuDetail = () => {
    Axios.get("https://cryptosharkglobal.com:3010/api/getMenuDetail").then(
      (responce) => {
        setMenuDataDetail(responce.data);
        setMenuDataFilter(responce.data);
      }
    );
  };

  const hideAlert = () => {
    setshowAlert({ id: 0, status: false });
  };
  const showAlertDialog = (tid) => {
    setshowAlert({ id: tid, status: true });
  };

  const DeleteItem = () => {
    Axios.get(
      `https://cryptosharkglobal.com:3010/api/deleteMenu/${showAlert.id}`
    ).then((responce) => {
      showMenuDetail();
      setshowAlert({ id: 0, status: false });
      setshowMsg({
        title: "Deleted...",
        msg: "Selected Record Sucessfully Deleted...",
        status: true,
      });
    });
  };
  const SaveData = () => {
    const NameError = nameValidator(MenuMasterDetail.value);
    const OutletError = OutletValidator(MenuMasterDetail.outletId);
    const CatError = CategoryValidator(MenuMasterDetail.catId);
    const PriceError = priceValidator(MenuMasterDetail.price);
    if (NameError || OutletError || CatError || PriceError) {
      setMenuMasterDetail({
        ...MenuMasterDetail,
        error: NameError
          ? NameError
          : "" + OutletError
          ? OutletError
          : "" + CatError
          ? CatError
          : "" + PriceError
          ? PriceError
          : "",
      });
      return;
    }
    Axios.post("https://cryptosharkglobal.com:3010/api/addEditMenu", {
      menu_id: MenuMasterDetail.id,
      menu_name: MenuMasterDetail.value,
      cat_id: MenuMasterDetail.catId,
      outletid: MenuMasterDetail.outletId,
      price: MenuMasterDetail.price,
      description: MenuMasterDetail.Description,
    }).then((responce) => {
      setMenuMasterDetail({
        value: "",
        error: false,
        id: 0,
        price: 0,
        catId: 0,
        outletid: 0,
        Description: "",
      });
      setAddEditVisiable(false);
      showMenuDetail();
      setshowMsg({
        title: "Record Saved...",
        msg: "Record Sucessfully Saved...",
        status: true,
      });
    });
  };

  const search = (searchText) => {
    setSearchMenu(searchText.toLowerCase());

    let filteredData = MenuDataDetail.filter(function (item) {
      return item.menu_name.toLowerCase().includes(searchText);
    });

    setMenuDataFilter(filteredData);
  };
  return (
    <View style={{ flex: 1 }}>
      <Modal
        animationType="fade"
        transparent={true}
        visible={AddEditVisiable}
        onRequestClose={() => {
          setAddEditVisiable(false);
        }}
      >
        <View style={style.centeredView}>
          <View style={style.modalView}>
            <Text>Outlet</Text>
            <View
              style={{
                flex: 1,
                marginHorizontal: 20,
                justifyContent: "center",
              }}
            >
              <Picker
                mode="dropdown"
                selectedValue={MenuMasterDetail.outletId}
                onValueChange={setOutletData}
              >
                <Picker.Item
                  color="#0087F0"
                  label="Select Outlet..."
                  value={0}
                  index={0}
                  key={0}
                />
                {OutletDetail.map((item, index) => (
                  <Picker.Item
                    color="#0087F0"
                    label={item.label}
                    value={item.value}
                    index={index}
                    key={item.value}
                  />
                ))}
              </Picker>
            </View>
            <Text>Category</Text>

            <Picker
              mode="dropdown"
              selectedValue={MenuMasterDetail.catId}
              onValueChange={setCategoryData}
            >
              <Picker.Item
                color="#0087F0"
                label="Select Category..."
                value={0}
                index={0}
                key={0}
              />
              {CategoryDetail.map((item, index) => (
                <Picker.Item
                  color="#0087F0"
                  label={item.label}
                  value={item.value}
                  index={index}
                  key={item.value}
                />
              ))}
            </Picker>
            <TextInput
              label="Menu"
              returnKeyType="next"
              value={MenuMasterDetail.value}
              onChangeText={(text) =>
                setMenuMasterDetail({
                  ...MenuMasterDetail,
                  value: text,
                  error: "",
                })
              }
              error={!!MenuMasterDetail.error}
              errorText={MenuMasterDetail.error}
            />
            <TextInput
              label="Price"
              returnKeyType="next"
              value={MenuMasterDetail.price}
              onChangeText={(text) =>
                setMenuMasterDetail({
                  ...MenuMasterDetail,
                  price: text,
                  error: "",
                })
              }
              error={!!MenuMasterDetail.error}
              errorText={MenuMasterDetail.error}
            />
            <TextInput
              label="Description"
              returnKeyType="done"
              value={MenuMasterDetail.Description}
              onChangeText={(text) =>
                setMenuMasterDetail({
                  ...MenuMasterDetail,
                  Description: text,
                  error: "",
                })
              }
              error={!!MenuMasterDetail.error}
              errorText={MenuMasterDetail.error}
            />
            <View>
              <Button mode="outlined" onPress={SaveData}>
                Save
              </Button>
              <Button mode="outlined" onPress={() => setAddEditVisiable(false)}>
                close
              </Button>
            </View>
          </View>
        </View>
      </Modal>

      <View>
        <Text style={style.heading}>Menu Detail</Text>
      </View>
      <View style={[style.itemContainer]}>
        <Button
          style={style.ButtonStyle}
          labelStyle={{ fontSize: 12 }}
          mode="contained"
          onPress={() => {
            setAddEditVisiable(true);
          }}
        >
          <Text>New Menu</Text>
        </Button>
        <SearchBar
          round={true}
          lightTheme={true}
          inputStyle={{ backgroundColor: "white" }}
          containerStyle={{
            backgroundColor: "#ffb300",
            borderWidth: 0,
            borderRadius: 0,
          }}
          inputContainerStyle={{ backgroundColor: "white" }}
          placeholderTextColor={"#g5g5g5"}
          placeholder="Search..."
          autoCapitalize="none"
          autoCorrect={false}
          onChangeText={search}
          value={SearchMenu}
        />
      </View>

      <FlatList
        data={MenuDataFilter}
        renderItem={(i) => {
          return (
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                justifyContent: "space-between",
                paddingHorizontal: 15,
                paddingVertical: 3,
                marginVertical: 5,
                backgroundColor: "#f7f9fa",
              }}
            >
              <View style={{ flexDirection: "column", flex: 1 }}>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: 3,
                  }}
                >
                  <Text style={style.TextStyle}>Item : </Text>
                  <Text style={style.TextStyle}>{i.item.menu_name}</Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: 3,
                  }}
                >
                  <Text style={style.TextStyle}>Category :</Text>
                  <Text style={style.TextStyle}>{i.item.cat_name}</Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: 3,
                  }}
                >
                  <Text style={style.TextStyle}>Outlet :</Text>
                  <Text style={style.TextStyle}>{i.item.Outlet_name}</Text>
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: 3,
                  }}
                >
                  <Text style={style.TextStyle}>Price :</Text>
                  <Text style={style.TextStyle}>{i.item.price}</Text>
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: 3,
                  }}
                >
                  <Text style={style.TextStyle}>Description :</Text>
                  <Text style={style.TextStyle}>{i.item.description}</Text>
                </View>
                <Button
                  mode="contained"
                  labelStyle={{ fontSize: 12 }}
                  style={{ marginHorizontal: 5, marginVertical: 5 }}
                  onPress={() => {
                    setMenuMasterDetail({
                      ...MenuMasterDetail,
                      value: i.item.menu_name,
                      id: i.item.id,
                      price: i.item.price,
                      catId: i.item.cat_id,
                      outletId: i.item.outletid,
                      description: i.item.Description,
                    });
                    setAddEditVisiable(true);
                  }}
                >
                  <Text>Edit</Text>
                </Button>
                <Button
                  style={{ marginHorizontal: 5, marginVertical: 5 }}
                  mode="contained"
                  labelStyle={{ fontSize: 12 }}
                  onPress={() => {
                    showAlertDialog(i.item.id);
                  }}
                >
                  <Text>Delete</Text>
                </Button>
              </View>
            </View>
          );
        }}
      ></FlatList>
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          backgroundColor: "#ecf0f1",
          padding: 8,
        }}
      ></View>
      <View>
        <Text>&nbsp;</Text>
        <Text>&nbsp;</Text>
        <Text>&nbsp;</Text>
        <Text>&nbsp;</Text>
      </View>
      <AwesomeAlert
        show={showAlert.status}
        showProgress={false}
        title="Delete Confirmation"
        message="Really Want To delete it..."
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showCancelButton={true}
        showConfirmButton={true}
        cancelText="No, cancel"
        confirmText="Yes, Delete It..."
        confirmButtonColor="#DD6B55"
        onCancelPressed={hideAlert}
        onConfirmPressed={DeleteItem}
      />

      <AwesomeAlert
        show={showMsg.status}
        showProgress={false}
        title={showMsg.title}
        message={showMsg.msg}
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showCancelButton={false}
        showConfirmButton={true}
        confirmText="Ok, Thanks..."
        confirmButtonColor="#DD6B55"
        onConfirmPressed={() => {
          setshowMsg({ title: "", msg: "", status: false });
        }}
      />
    </View>
  );
};

export default MenuDetail;

const style = StyleSheet.create({
  screen: {
    flex: 1,
    justifyContent: "center",
  },
  card: {
    margin: 4,
    width: 100,
    height: 50,
  },
  heading: {
    backgroundColor: "#00A6A6",
    color: "#1E1014",
    fontWeight: "bold",
    fontSize: 20,
    textAlign: "center",
    paddingVertical: 5,
    marginTop: 5,
  },
  ImageStyle: {
    flex: 1,
    alignItems: "flex-start",
    resizeMode: "contain",
  },
  ButtonViewStyle: {
    flexDirection: "row",
    margin: 7,
    justifyContent: "space-around",
  },
  ButtonStyle: {
    margin: 7,
    width: 150,
  },
  gridView: {
    marginTop: 10,
    flex: 1,
  },
  itemContainer: {
    backgroundColor: "#ffb300",
    borderRadius: 10,
    padding: 10,
    flexDirection: "row",
    justifyContent: "center",
  },
  ContainerStyle: {
    textAlign: "center",
  },
  TextStyle: {
    color: "#1E1014",
    fontSize: 15,
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "stretch",
    marginTop: 22,
  },
  modalView: {
    margin: 10,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 10,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
  },
  grid_plan: {
    flexDirection: "row",
    borderRadius: 10,
    height: 70,
    borderWidth: 1,
    backgroundColor: "#EEBA0B",
    padding: 1,
    margin: 5,
    elevation: 2,
    textAlign: "center",
    width: "100%",
  },
});
