export function nameValidator(name) {
  if (!name) return "Name can't be empty.";
  return "";
}
export function priceValidator(price) {
  if (price <= 0) return "Price can't be empty.";
  return "";
}
export function OutletValidator(outletId) {
  if (outletId == 0) return "Outlet can't be empty.";
  return "";
}
export function CategoryValidator(CategoryId) {
  if (CategoryId == 0) return "Category can't be empty.";
  return "";
}
