import React, { Component, useState, useEffect } from "react";
import AwesomeAlert from "react-native-awesome-alerts";
import { CommonActions } from "@react-navigation/native";
import {
  ScrollView,
  Switch,
  StyleSheet,
  Text,
  View,
  Modal,
  Share,
  Image,
  Platform,
} from "react-native";
import Clipboard from "@react-native-clipboard/clipboard";
import * as DocumentPicker from "expo-document-picker";
import { SectionGrid } from "react-native-super-grid";
// import { Avatar, ListItem, Icon } from "react-native-elements";
import { Avatar, Button, Checkbox, IconButton } from "react-native-paper";
import { useSelector, useDispatch } from "react-redux";
import Axios from "axios";
import InfoText from "../components/InfoText";
import BaseIcon from "../components/Icon";
import Colors from "../constants/Colors";
import { ListItem } from "react-native-elements";
import { TouchableOpacity } from "react-native-gesture-handler";
import TextInput from "../components/TextInput";
import { emailValidator } from "../helpers/emailValidator";
import { passwordValidator } from "../helpers/passwordValidator";
import { nameValidator } from "../helpers/nameValidator";
import { setActiveRenew, setUpdate_Email } from "../store/actions/userAction";
import Paragraph from "../components/Paragraph";
import { useNavigation } from "@react-navigation/native";
import * as Linking from "expo-linking";
import * as MediaLibrary from "expo-media-library";
import * as FileSystem from "expo-file-system";
import * as Permissions from "expo-permissions";
const FileDownload = require("js-file-download");

const Profile = (props) => {
  const [doc, setDoc] = useState();
  const [userDetail, setuseDetail] = useState(null);
  const [userMobile, setuserMobile] = useState(null);
  const [EditEmailVisiable, setEditEmailVisiable] = useState(false);
  const [EditPasswordVisiable, setEditPasswordVisiable] = useState(false);
  const [showMsg, setshowMsg] = useState({ title: "", msg: "", status: false });
  const [SettingVisiable, setSettingVisiable] = useState(false);
  const [QrCodeVisiable, setQrCodeVisiable] = useState(false);

  const [PromoVisiable, setPromoVisiable] = useState(false);
  const [Logo_Image, setLogo_Image] = useState(null);
  const [ExcelFile, setExcelFile] = useState(null);
  const [Logo_Source, setLogo_Source] = useState(null);
  const [Slider1_Image, setSlider1_Image] = useState(null);
  const [Slider1_Source, setSlider1_Source] = useState(null);
  const [Slider2_Image, setSlider2_Image] = useState(null);
  const [Slider2_Source, setSlider2_Source] = useState(null);
  const [Slider3_Image, setSlider3_Image] = useState(null);
  const [Slider3_Source, setSlider3_Source] = useState(null);

  const [ModifyCaption, setModifyCaption] = useState(false);
  const [CaptionTitle, setCaptionTitle] = useState({
    MainCatHeading: "",
    SubCatHeading: "",
    ItemHeading: "",
    NoteHeading: "",
    NoteVisiable: false,
    show_whatsapp_button: false,
    error: "",
  });

  const [QRSize, setQRSize] = useState({
    value: "",
    error: "",
  });

  const [Emails, setEmails] = useState({
    value: "",
    mobile_no: "",
    error: "",
    MobileError: "",
  });

  const [OldPassword, setOldPassword] = useState({
    value: "",
    error: "",
  });
  const [NewPassword, setNewPassword] = useState({
    value: "",
    error: "",
  });
  const [Images, setImages] = useState([]);

  const navigation = useNavigation();
  const dispatch = useDispatch();
  const { client_id, userid } = useSelector((state) => state.userInfo);
  useEffect(() => {
    if (userid) {
      Axios.get(
        `https://cryptosharkglobal.com:3010/api/getuserOnId/${userid}`
      ).then((responce) => {
        setuseDetail(responce.data[0]);
      });
    }
  }, [userid]);

  const downloadFile = async () => {
    const filename = "QR_Code.png";
    const uri = `https://cryptosharkglobal.com:3010/download_qrcode/${client_id}`;

    Axios({
      url: uri,
      method: "GET",
      responseType: "blob", // Important
    }).then((response) => {
      FileDownload(response.data, filename);
    });
  };
  const UploadExcelFile = async () => {
    let result = await DocumentPicker.getDocumentAsync({
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      copyToCacheDirectory: false,
    });
    setExcelFile(result);
    let { name, size, uri } = result;
    // console.log(name, " ", size, " ", uri);
    // console.log(uri.substring(uri.indexOf("base64,") + 7, uri.length));
    // let file_to_upload = {
    //   uri: "file://" + uri,
    //   name: name,
    //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    // };
    // let formData = new FormData();
    // // formData.append("title", client_id + "_");
    // formData.append("file", file_to_upload);
    // console.log(result);
    // Axios.post("https://cryptosharkglobal.com:3010/api/upload", formData, {
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "multipart/form-data",
    //   },
    // })
    //   .then((res) => {
    //     console.log("File Upload success ", res);
    //   })
    //   .catch((err) => console.log(err));
    Axios.post("https://cryptosharkglobal.com:3010/api/upload", {
      client_id: client_id,
      name: client_id + "_" + name,
      file_source: uri.substring(uri.indexOf("base64,") + 7, uri.length),
    })
      .then((res) => {
        setshowMsg({
          title: "Data Uploaded...",
          msg: "Data Uploaded Sucessfully...",
          status: true,
        });
      })
      .catch((err) => {
        if (err.response.status == 406) {
          setshowMsg({
            title: "Empty Data Uploaded...",
            msg: "No Data Found To Upload...",
            status: true,
          });
        } else if (err.response.status == 411) {
          setshowMsg({
            title: "Data Field Unmatched...",
            msg: "Please Check Data Field or Download Format File...",
            status: true,
          });
        }
      });
  };

  const DownloadExcelFormat = async () => {
    const filename = "item_list.xlsx";
    const uri = "https://cryptosharkglobal.com:3010/download_format";

    Axios({
      url: uri,
      method: "GET",
      responseType: "blob", // Important
    }).then((response) => {
      FileDownload(response.data, "ItemUploadFormat.xlsx");
    });
  };

  return (
    <View style={{ flex: 1 }}>
      <Modal
        animationType="fade"
        transparent={true}
        visible={QrCodeVisiable}
        onRequestClose={() => {
          setQrCodeVisiable(false);
        }}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Image
              style={styles.QrCode}
              source={{
                uri: `http://api.qrserver.com/v1/create-qr-code/?data=https://trueworldlaunch.network&size=300x300&bgcolor=white`,
              }}
            />
            <View style={{ marginTop: 5, flexDirection: "row" }}>
              <Button
                style={{ margin: 3 }}
                mode="outlined"
                onPress={downloadFile}
              >
                Download
              </Button>
              <Button
                style={{ margin: 3 }}
                mode="outlined"
                onPress={() => setQrCodeVisiable(false)}
              >
                close
              </Button>
            </View>
          </View>
        </View>
      </Modal>
      <ScrollView style={styles.scroll}>
        <View>
          <InfoText text={userDetail ? userDetail.email : ""} />
        </View>
        <View style={{ flex: 1 }}>
          <ListItem
            key={6}
            bottomDivider={true}
            containerStyle={styles.listItemContainer}
          >
            <TouchableOpacity
              onPress={() => {
                setQrCodeVisiable(true);
              }}
            >
              <ListItem.Title>Get QR Code</ListItem.Title>
            </TouchableOpacity>
            <ListItem.Chevron />
          </ListItem>
          <ListItem
            key={7}
            bottomDivider={true}
            containerStyle={styles.listItemContainer}
          >
            <TouchableOpacity onPress={DownloadExcelFormat}>
              <ListItem.Title>Download Format for Data</ListItem.Title>
            </TouchableOpacity>
            <ListItem.Chevron />
          </ListItem>
          <ListItem
            key={8}
            bottomDivider={true}
            containerStyle={styles.listItemContainer}
          >
            <TouchableOpacity onPress={UploadExcelFile}>
              <ListItem.Title>Upload Item Data</ListItem.Title>
            </TouchableOpacity>
            <ListItem.Chevron />
          </ListItem>
        </View>
      </ScrollView>
      <View>
        <Text></Text>
      </View>
      <AwesomeAlert
        show={showMsg.status}
        showProgress={false}
        title={showMsg.title}
        message={showMsg.msg}
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showCancelButton={false}
        showConfirmButton={true}
        confirmText="Ok, Thanks..."
        confirmButtonColor="#DD6B55"
        onConfirmPressed={() => {
          setshowMsg({ title: "", msg: "", status: false });
        }}
      />
    </View>
  );
};

export default Profile;

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    justifyContent: "center",
  },
  scroll: {
    flex: 1,
    backgroundColor: "white",
  },
  userRow: {
    // alignItems: "center",
    flexDirection: "row",
    paddingBottom: 8,
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 6,
  },
  userImage: {
    marginRight: 12,
  },
  listItemContainer: {
    height: 55,
    borderWidth: 0.5,
    borderColor: "#ECECEC",
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "stretch",
    marginTop: 22,
  },
  modalView: {
    margin: 10,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 10,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
  },
  logo: {
    flex: 1,
    alignItems: "flex-start",
    resizeMode: "contain",
  },
  QrCode: {
    flex: 1,
    alignItems: "flex-start",
    resizeMode: "contain",
    height: 200,
    width: 200,
  },
  gridView: {
    marginTop: 5,
    flex: 1,
    backgroundColor: "white",
  },
  itemContainer: {
    backgroundColor: "#ffb300",
    justifyContent: "center",
    borderRadius: 10,
    padding: 10,
    height: 150,
  },
  subheading: {
    backgroundColor: "#fcba03",
    color: "#1E1014",
    fontWeight: "bold",
    fontSize: 15,
    textAlign: "center",
    paddingVertical: 5,
    marginTop: 2,
    marginBottom: 2,
  },
});
