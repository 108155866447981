import React, { useEffect, useState } from "react";

import { createStackNavigator } from "@react-navigation/stack";
import { NavigationContainer } from "@react-navigation/native";
import { createDrawerNavigator } from "@react-navigation/drawer";

import AsyncStorage from "@react-native-async-storage/async-storage";
// import MainDrawerNavigation from "./navigation/MainDrawerNavigation";
import UnauthNavigation from "./navigation/UnauthNavigation";

import { useSelector } from "react-redux";
import AuthNavigation from "./navigation/AuthNavigation";

const Stack = createStackNavigator();
const Drawer = createDrawerNavigator();

const Routes = () => {
  const [isAuth, setisAuth] = useState(false);

  const userid = useSelector((state) => state.userInfo.userid);
  useEffect(() => {
    if (userid) {
      setisAuth(true);
    } else {
      setisAuth(false);
    }
  }, [userid]);

  // useEffect(() => {
  //   getData();
  // }, []);

  // const { tInfo, chainId, RpcUrl, USD_Contract } = useSelector(
  //   (state) => state.userInfo
  // );
  // useEffect(() => {
  //   find_wallet_balance(RpcUrl, chainId, tInfo, USD_Contract);
  // }, [tInfo]);

  return (
    <NavigationContainer>
      {isAuth ? <AuthNavigation /> : <UnauthNavigation />}
    </NavigationContainer>
  );
};
export default Routes;
