import React from "react";
import { TouchableOpacity } from "react-native-gesture-handler";
import { View, Text, StyleSheet, Image } from "react-native";
import { Button, Card } from "react-native-paper";

const Tile = (props) => {
  return (
    // <Card style={style.card}>
    //   <Card.Cover source={itemData.item.imgSrc} />
    //   <Card.Title title={itemData.item.planName} />
    //   <Card.Content>
    //     <Paragraph>
    //       The Abandoned Ship is a wrecked ship located on Route 108 in Hoenn.
    //     </Paragraph>
    //   </Card.Content>
    // </Card>
    // <TouchableOpacity style={{ flex: 1 }} onPress={props.onSelect}>
    <Card style={style.grid_plan}>
      <View>
        <Image source={{ uri: props.item.imgSrc }} style={style.ImageStyle} />
        <View style={style.ContainerStyle}>
          <Text style={style.TextStyle}>{props.item.planName}</Text>
          <Text style={style.TextEntry}>
            Entry Amount :{props.item.entryAmount}
          </Text>

          <Button
            loading={props.buton_loading}
            disabled={props.button_will_disabled || props.buton_loading}
            mode="contained"
            labelStyle={{ fontSize: 16 }}
            style={{ marginTop: 5 }}
            onPress={props.onSelect}
          >
            <Text>{props.button_caption}</Text>
          </Button>
        </View>
      </View>
    </Card>
    // </TouchableOpacity>
  );
};

const style = StyleSheet.create({
  grid_plan: {
    flexDirection: "column",
    borderRadius: 10,
    height: 200,
    borderWidth: 1,
    backgroundColor: "#EEBA0B",
    padding: 1,
    margin: 5,
    elevation: 2,
    textAlign: "center",
    width: "90%",
    alignItems: "center",
  },
  ImageStyle: {
    width: 100,
    height: 100,
    alignItems: "center",
    flex: 1,
    resizeMode: "contain",
  },

  TextStyle: {
    color: "#1E1014",
    //fontWeight: "bold",
    fontSize: 20,
    textAlign: "center",
  },
  TextEntry: {
    color: "#1E1014",
    //fontWeight: "bold",
    fontSize: 12,
    textAlign: "center",
  },
});

export default Tile;
