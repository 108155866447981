import { createStore, applyMiddleware, compose } from "redux";
import { logger } from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage";
import AsyncStorage from "@react-native-async-storage/async-storage";
import root_reducer from "./root_reducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistConfig = {
  key: "ebay_store",
  storage: AsyncStorage,
};
const persistedReducer = persistReducer(persistConfig, root_reducer);

const middlewares = [logger];

const store = createStore(persistedReducer);
const persistor = persistStore(store);
export default store;
export { persistor };
