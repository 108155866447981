import React, { Component, useState, useEffect } from "react";
import AwesomeAlert from "react-native-awesome-alerts";
import {
  ScrollView,
  Switch,
  StyleSheet,
  Text,
  View,
  Image,
  Modal,
  FlatList,
} from "react-native";
import Axios from "axios";
import { Button, Headline } from "react-native-paper";
import { useNavigation } from "@react-navigation/native";
import TextInput from "../components/TextInput";
import { nameValidator } from "../helpers/nameValidator";
const OutletDetail = () => {
  const [showAlert, setshowAlert] = useState({ id: 0, status: false });
  const [diffVersionAlert, setdiffVersionAlert] = useState(false);
  const [showMsg, setshowMsg] = useState({ title: "", msg: "", status: false });
  const [AddEditVisiable, setAddEditVisiable] = useState(false);
  const [OutletName, setOutletName] = useState({
    value: "",
    error: "",
    id: 0,
  });
  const navigation = useNavigation();
  const [OutletDetail, setOutletDetail] = useState([]);
  useEffect(() => {
    Axios.get("https://cryptosharkglobal.com:3010/api/getOutletDetail").then(
      (responce) => {
        setOutletDetail(responce.data);
      }
    );
  }, []);
  const showOutlets = () => {
    Axios.get("https://cryptosharkglobal.com:3010/api/getOutletDetail").then(
      (responce) => {
        setOutletDetail(responce.data);
      }
    );
  };

  const hideAlert = () => {
    setshowAlert({ id: 0, status: false });
  };
  const showAlertDialog = (tid) => {
    setshowAlert({ id: tid, status: true });
  };

  const DeleteItem = () => {
    Axios.get(
      `https://cryptosharkglobal.com:3010/api/deleteOutlet/${showAlert.id}`
    ).then((responce) => {
      showOutlets();
      setshowAlert({ id: 0, status: false });
      setshowMsg({
        title: "Deleted...",
        msg: "Selected Record Sucessfully Deleted...",
        status: true,
      });
    });
  };
  const SaveData = () => {
    const OutletNameError = nameValidator(OutletName.value);
    if (OutletNameError) {
      setOutletName({ ...OutletName, error: OutletNameError });
      return;
    }
    Axios.post("https://cryptosharkglobal.com:3010/api/addEditOutlet", {
      outletid: OutletName.id,
      outlet_name: OutletName.value,
    }).then((responce) => {
      setOutletName({ value: "", error: false, id: 0 });
      setAddEditVisiable(false);
      showOutlets();
      setshowMsg({
        title: "Record Saved...",
        msg: "Record Sucessfully Saved...",
        status: true,
      });
    });
  };

  return (
    <View style={{ flex: 1 }}>
      <Modal
        animationType="fade"
        transparent={true}
        visible={AddEditVisiable}
        onRequestClose={() => {
          setAddEditVisiable(false);
        }}
      >
        <View style={style.centeredView}>
          <View style={style.modalView}>
            <Text>Outlet Name</Text>
            <TextInput
              label="Outlet Name"
              returnKeyType="done"
              value={OutletName.value}
              onChangeText={(text) =>
                setOutletName({ ...OutletName, value: text, error: "" })
              }
              error={!!OutletName.error}
              errorText={OutletName.error}
            />
            <View>
              <Button mode="outlined" onPress={SaveData}>
                Save
              </Button>
              <Button mode="outlined" onPress={() => setAddEditVisiable(false)}>
                close
              </Button>
            </View>
          </View>
        </View>
      </Modal>

      <View>
        <Text style={style.heading}>Outlet Detail</Text>
      </View>
      <View style={[style.itemContainer]}>
        <Button
          style={style.ButtonStyle}
          labelStyle={{ fontSize: 12 }}
          mode="contained"
          onPress={() => {
            setAddEditVisiable(true);
          }}
        >
          <Text>New Outlet</Text>
        </Button>
        <Button
          style={style.ButtonStyle}
          labelStyle={{ fontSize: 12 }}
          mode="contained"
          onPress={() => {}}
        >
          <Text>Show QrCode</Text>
        </Button>
      </View>

      <FlatList
        data={OutletDetail}
        renderItem={(i) => {
          return (
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                justifyContent: "space-between",
                paddingHorizontal: 15,
                paddingVertical: 3,
                marginVertical: 5,
                backgroundColor: "#f7f9fa",
              }}
            >
              <Text style={style.TextStyle}>{i.item.Outlet_name}</Text>
              <View style={{ flexDirection: "row" }}>
                <Button
                  style={{ marginHorizontal: 5 }}
                  mode="contained"
                  labelStyle={{ fontSize: 16 }}
                  onPress={() => {
                    setOutletName({
                      ...OutletName,
                      value: i.item.Outlet_name,
                      id: i.item.id,
                    });
                    setAddEditVisiable(true);
                  }}
                >
                  <Text>Edit</Text>
                </Button>
                <Button
                  style={{ marginHorizontal: 5 }}
                  mode="contained"
                  labelStyle={{ fontSize: 16 }}
                  onPress={() => {
                    showAlertDialog(i.item.id);
                  }}
                >
                  <Text>Delete</Text>
                </Button>
              </View>
            </View>
          );
        }}
      ></FlatList>

      <View>
        <Text>&nbsp;</Text>
        <Text>&nbsp;</Text>
        <Text>&nbsp;</Text>
        <Text>&nbsp;</Text>
      </View>
      <AwesomeAlert
        show={showAlert.status}
        showProgress={false}
        title="Delete Confirmation"
        message="Really Want To delete it..."
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showCancelButton={true}
        showConfirmButton={true}
        cancelText="No, cancel"
        confirmText="Yes, Delete It..."
        confirmButtonColor="#DD6B55"
        onCancelPressed={hideAlert}
        onConfirmPressed={DeleteItem}
      />
      <AwesomeAlert
        show={showMsg.status}
        showProgress={false}
        title={showMsg.title}
        message={showMsg.msg}
        closeOnTouchOutside={false}
        closeOnHardwareBackPress={false}
        showCancelButton={false}
        showConfirmButton={true}
        confirmText="Ok, Thanks..."
        confirmButtonColor="#DD6B55"
        onConfirmPressed={() => {
          setshowMsg({ title: "", msg: "", status: false });
        }}
      />
    </View>
  );
};

export default OutletDetail;

const style = StyleSheet.create({
  screen: {
    flex: 1,
    justifyContent: "center",
  },
  card: {
    margin: 4,
    width: 100,
    height: 50,
  },
  heading: {
    backgroundColor: "#00A6A6",
    color: "#1E1014",
    fontWeight: "bold",
    fontSize: 20,
    textAlign: "center",
    paddingVertical: 5,
    marginTop: 5,
  },
  ImageStyle: {
    flex: 1,
    alignItems: "flex-start",
    resizeMode: "contain",
  },
  ButtonViewStyle: {
    flexDirection: "row",
    margin: 7,
    justifyContent: "space-around",
  },
  ButtonStyle: {
    margin: 7,
    width: 150,
  },
  gridView: {
    marginTop: 10,
    flex: 1,
  },
  itemContainer: {
    backgroundColor: "#ffb300",
    borderRadius: 10,
    padding: 10,
    flexDirection: "row",
    justifyContent: "center",
  },
  ContainerStyle: {
    textAlign: "center",
  },
  TextStyle: {
    color: "#1E1014",
    fontSize: 18,
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "stretch",
    marginTop: 22,
  },
  modalView: {
    margin: 10,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 10,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
  },
  grid_plan: {
    flexDirection: "row",
    borderRadius: 10,
    height: 70,
    borderWidth: 1,
    backgroundColor: "#EEBA0B",
    padding: 1,
    margin: 5,
    elevation: 2,
    textAlign: "center",
    width: "100%",
  },
});
